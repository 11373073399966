import PropTypes from 'prop-types';
import {
  Dropdown,
  Menu,
  message,
  Popconfirm,
  Space,
  Table,
  Tooltip,
} from 'antd';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import EllipsisOutlined from '@ant-design/icons/lib/icons/EllipsisOutlined';
import dayjs from 'dayjs';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import WaypointsModal from './modal/WaypointsModal';
import { deleteShippingTaskWaypoint } from '../../../../../../../logic/task/TaskApiFunctions';
import { userInformations } from '../../../../../../../contex';
import useCheckMobileScreen from '../../../../../../../hooks/useCheckMobileScreen';
import { ErrorBoundary } from '../../../../../../ErrorBoundary';
import ScrollingLoadedList from '../../../../../../mobile/scrollingLoadedList/ScrollingLoadedList';
import TaskWayPointsListItem from '../../../../../../mobile/task/modal/TaskWayPointsListItem';
import SearchDataFilter from '../../../../../../leeds/filters/SearchData';
import {
  changeColumnSizeInColumnsGroup,
  changeSizeColumn,
  convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters,
  prepareColumnsForResizing,
  sortingColumnsTable,
} from '../../../../../../settingTable/sortingColumnsTable';
import useStateRef from 'react-usestateref';
import { editTableColumn } from '../../../../../../../logic/profileInfo/mainTable/MainTableApiFunctions';
import { getProfileInfo } from '../../../../../../../logic/account/AccountsApiFunctions';
import { userInfoHaveViewAllBranch } from '../../../../../../../logic/account/utils';
import filterActiveColumns from '../../../../../../table/utils';
import ResizableTableColumnHeader from '../../../../../../table/ResizableTableColumnHeader';
import { getFiltersTable } from '../../../../../../../utils/getFilters';
import FilterDatePicker from '../../../../../../leeds/filters/FilterDatePicker';
import RangeNumberFilters from '../../../../../../leeds/filters/RangeNumberFilters';
import UniversalFiltersModal from '../../../../../../mobile/filtersModal/UniversalFiltersModal';

const WaypointsTable = (props) => {
  const tableName = 'tasks.waypoints';
  const { userInfo } = useContext(userInformations);
  const [currEditWaypoint, setCurrEditWaypoint] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const isMobile = useCheckMobileScreen();
  const [baseColumns, setBaseColumns, baseColumnsRef] = useStateRef([]);
  const [
    ,
    setColumnFiltersFromTableComponent,
    columnFiltersFromTableComponentRef,
  ] = useStateRef([]);

  useEffect(() => {
    const col = sortingColumnsTable(
      userInfo,
      tableName,
      columns,
      props.visibleFilters,
    );
    const preparedColumnsForResizing = prepareColumnsForResizing(
      col,
      handleResize,
    );

    setBaseColumns(preparedColumnsForResizing);
  }, [props.visibleFilters]);

  useEffect(() => {
    if (!props.tableFilters) {
      setColumnFiltersFromTableComponent([]);
    }
  }, [props.tableFilters]);

  const handleEditWaypoint = useCallback(
    (waypointRow) => {
      setCurrEditWaypoint(waypointRow);
      setVisibleModal(true);
    },
    [setCurrEditWaypoint, setVisibleModal],
  );

  const handleCloseModal = useCallback(async () => {
    setVisibleModal(false);
    props.handleUpdateList(props.tableFilters);
    setCurrEditWaypoint(null);
  }, [setVisibleModal, props.tableFilters]);

  const handleDeleteWaypoint = useCallback(
    async (waypointId) => {
      const res = await deleteShippingTaskWaypoint(waypointId, userInfo);
      if (res) {
        message.success('Пункт успешно удален');
        props.handleUpdateList(props.tableFilters);
      }
    },
    [props.handleUpdateList, props.tableFilters],
  );

  const menu = (waypoint) => {
    return (
      <Menu style={{ width: 200 }}>
        <Menu.Item
          icon={<EditOutlined />}
          onClick={() => {
            handleEditWaypoint(waypoint);
          }}
        >
          Редактировать
        </Menu.Item>
        <Popconfirm
          title="Вы действительно хотите удалить запись?"
          onConfirm={async () => await handleDeleteWaypoint(waypoint?.id)}
          okText="Да"
          cancelText="Нет"
          placement="rightBottom"
        >
          <Menu.Item icon={<DeleteOutlined />}>Удалить</Menu.Item>
        </Popconfirm>
      </Menu>
    );
  };
  const columns = [
    {
      title: '',
      key: 'action',
      width: 50,
      align: 'center',
      uniqueIndex: 'action',
      index: 2,
      render: (waypoint) => (
        <Space size="middle">
          <Dropdown overlay={menu(waypoint)} trigger={['hover']}>
            <EllipsisOutlined style={{ cursor: 'pointer', fontSize: '24px' }} />
          </Dropdown>
        </Space>
      ),
    },
    {
      title: 'Операция',
      dataIndex: 'operation',
      key: 'operation',
      uniqueIndex: 'operation',
      index: 4,
      width: 250,
      ellipsis: {
        showTitle: false,
      },
      render: (operation) =>
        operation?.name ? (
          <Tooltip placement="topLeft" title={operation.name}>
            {operation.name}
          </Tooltip>
        ) : (
          ''
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Операция'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.operation__name__icontains
              }
              columnKey={'operation__name__icontains'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'operation',
          key: 'operation',
          uniqueIndex: 'operation',
          index: 4,
          ellipsis: {
            showTitle: false,
          },
          width: 250,
          render: (operation) =>
            operation?.name ? (
              <Tooltip placement="topLeft" title={operation.name}>
                {operation.name}
              </Tooltip>
            ) : (
              ''
            ),
        },
      ],
    },
    {
      title: 'Пункт',
      uniqueIndex: 'waypointGroup',
      isGroup: true,
      columnId: 'active',
      disableResize: true,
      children: [
        {
          title: 'Название',
          width: 250,
          dataIndex: 'name',
          key: 'name',
          uniqueIndex: 'name',
          index: 6,
          ellipsis: {
            showTitle: false,
          },
          render: (name) =>
            name ? (
              <Tooltip placement="topLeft" title={name}>
                {name}
              </Tooltip>
            ) : (
              ''
            ),
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Название'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current?.name__icontains
                  }
                  columnKey={'name__icontains'}
                />
              ),
              visible: props.visibleFilters,
              width: 250,
              dataIndex: 'name',
              key: 'name',
              //uniqueIndex: 'name',
              index: 6,
            },
          ],
        },
        {
          title: 'Пункт',
          width: 250,
          dataIndex: 'waypoint',
          key: 'waypoint',
          uniqueIndex: 'waypoint',
          index: 8,
          ellipsis: {
            showTitle: false,
          },
          render: (waypoint) =>
            waypoint ? (
              <Tooltip
                placement="topLeft"
                title={`${waypoint.name} ${waypoint.code}`}
              >
                {waypoint.name ?? 'Без названия'}
                {waypoint?.code ? `-${waypoint.code}` : ''}
              </Tooltip>
            ) : (
              ''
            ),
          children: [
            {
              title: (
                <SearchDataFilter
                  inputPlaceholder={'Пункт'}
                  confirm={(filtersTable) => handleTableChange(filtersTable)}
                  tableFilters={columnFiltersFromTableComponentRef.current}
                  selectedKeys={
                    columnFiltersFromTableComponentRef.current
                      ?.waypoint__icontains
                  }
                  columnKey={'waypoint__icontains'}
                />
              ),
              visible: props.visibleFilters,
              width: 250,
              dataIndex: 'waypoint',
              key: 'waypoint',
              //uniqueIndex: 'waypoint',
              render: (waypoint) =>
                waypoint ? (
                  <Tooltip
                    placement="topLeft"
                    title={`${waypoint.name} ${waypoint.code}`}
                  >
                    {waypoint.name}-{waypoint.code}
                  </Tooltip>
                ) : (
                  ''
                ),
              index: 8,
            },
          ],
        },
      ],
    },
    {
      title: 'Контрагент',
      dataIndex: 'agent_line',
      key: 'agent_line',
      uniqueIndex: 'agent_line',
      index: 10,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Контрагент'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.agent_line__icontains
              }
              columnKey={'agent_line__icontains'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'agent_line',
          key: 'agent_line',
          uniqueIndex: 'agent_line',
          index: 10,
          width: 150,
        },
      ],
    },
    {
      title: 'Контактное лицо',
      dataIndex: 'phone_line',
      key: 'phone_line',
      uniqueIndex: 'phone_line',
      index: 12,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Контактное лицо'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.phone_line__icontains
              }
              columnKey={'phone_line__icontains'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'phone_line',
          key: 'phone_line',
          uniqueIndex: 'phone_line',
          index: 12,
          width: 150,
        },
      ],
    },
    {
      title: 'Адрес',
      dataIndex: 'address',
      key: 'address',
      uniqueIndex: 'address',
      index: 14,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (address) =>
        address ? (
          <Tooltip placement="topLeft" title={address}>
            {address}
          </Tooltip>
        ) : (
          ''
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Адрес'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.address__icontains
              }
              columnKey={'address__icontains'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'address',
          key: 'address',
          uniqueIndex: 'address',
          index: 14,
          width: 150,
        },
      ],
    },
    {
      title: 'Расстояние (км)',
      dataIndex: 'distance',
      key: 'distance',
      uniqueIndex: 'distance',
      index: 16,
      width: 150,
      children: [
        {
          title: (
            <RangeNumberFilters
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.distance__range
              }
              columnKey={'distance__range'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'distance',
          key: 'distance',
          uniqueIndex: 'distance',
          index: 16,
          width: 150,
        },
      ],
    },
    {
      title: 'Дата',
      dataIndex: 'load_plan',
      key: 'date',
      uniqueIndex: 'date_load_plan',
      index: 24,
      width: 150,
      render: (load_plan) => {
        return load_plan ? dayjs(load_plan).format('DD-MM-YYYY') : '-';
      },
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.load_plan__periodDate
              }
              columnKey={'load_plan__periodDate'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'load_plan',
          key: 'date',
          uniqueIndex: 'date_load_plan',
          index: 24,
          width: 150,
          render: (load_plan) => {
            return load_plan ? dayjs(load_plan).format('DD-MM-YYYY') : '-';
          },
        },
      ],
    },
    {
      title: 'Планируемое вр. погрузки',
      dataIndex: 'load_plan',
      key: 'load_plan',
      uniqueIndex: 'load_plan',
      index: 20,
      width: 200,
      render: (load_plan) => {
        return load_plan ? dayjs(load_plan).format('HH:mm') : '-';
      },
      children: [
        {
          title: (
            <FilterDatePicker
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current
                  ?.load_plan__periodDate
              }
              columnKey={'load_plan__periodDate'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'load_plan',
          key: 'load_plan',
          uniqueIndex: 'load_plan',
          index: 20,
          width: 200,
          render: (load_plan) => {
            return load_plan ? dayjs(load_plan).format('HH:mm') : '-';
          },
        },
      ],
    },
    {
      title: 'Комментарий',
      dataIndex: 'comment',
      key: 'comment',
      uniqueIndex: 'comment',
      index: 22,
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      render: (comment) =>
        comment ? (
          <Tooltip placement="topLeft" title={comment}>
            {comment}
          </Tooltip>
        ) : (
          ''
        ),
      children: [
        {
          title: (
            <SearchDataFilter
              inputPlaceholder={'Комментарий'}
              confirm={(filtersTable) => handleTableChange(filtersTable)}
              tableFilters={columnFiltersFromTableComponentRef.current}
              selectedKeys={
                columnFiltersFromTableComponentRef.current?.comment__icontains
              }
              columnKey={'comment__icontains'}
            />
          ),
          visible: props.visibleFilters,
          dataIndex: 'comment',
          key: 'comment',
          uniqueIndex: 'comment',
          index: 22,
          width: 150,
        },
      ],
    },
  ];

  const handleResize = useCallback(
    (index) =>
      async (e, { size, uniqueIndex, isGroupItem }) => {
        let databaseColumnsId = null;
        const copyColumns = [...baseColumnsRef.current];

        if (!isGroupItem) {
          setBaseColumns((columns) => {
            return changeSizeColumn(columns, index, size);
          });

          databaseColumnsId = copyColumns[index]?.idInDatabase;
        } else {
          setBaseColumns((columns) => {
            const { nextColumns, idInDatabase } =
              changeColumnSizeInColumnsGroup(columns, index, uniqueIndex, size);
            databaseColumnsId = idInDatabase;
            return nextColumns;
          });
        }

        if (!databaseColumnsId) return;

        await editTableColumn(databaseColumnsId, { width: size }, userInfo);
        const res = await getProfileInfo(userInfo);
        if (res) {
          userInfo.current = res;
          const haveViewBranch = userInfoHaveViewAllBranch(res);
          if (haveViewBranch) {
            userInfo.current['haveViewBranch'] = true;
          }
        }
      },
    [baseColumnsRef.current],
  );

  const filteringColumnsChildrenPillarByVisible = useMemo(() => {
    const copyColumns = [...baseColumns];
    return filterActiveColumns(copyColumns);
  }, [baseColumns]);

  const handleTableChange = (filtersTable) => {
    const { filters } = getFiltersTable({ current: 1 }, filtersTable);
    setColumnFiltersFromTableComponent(filtersTable);
    props.handleUpdateList(filters);
  };

  return (
    <>
      {!isMobile ? (
        <Table
          onRow={(waypoint) => {
            return {
              onDoubleClick: () => {
                handleEditWaypoint(waypoint);
              },
            };
          }}
          components={{
            header: {
              cell: ResizableTableColumnHeader,
            },
          }}
          rowClassName={(record, index) =>
            index % 2 === 0
              ? 'black-text table-row-color'
              : 'black-text table-row-white'
          }
          loading={props.loading}
          size="small"
          pagination={{
            position: ['none'],
            size: 'middle',
            showSizeChanger: false,
          }}
          columns={filteringColumnsChildrenPillarByVisible}
          dataSource={props.waypointsDataList}
          scroll={{ x: 1350 }}
          bordered
        />
      ) : (
        <div style={{ paddingTop: '5px' }}>
          <ErrorBoundary>
            <ScrollingLoadedList
              dataList={props.waypointsDataList}
              totalData={props.waypointsDataList?.length}
              loadMoreItems={async () => {
                //handleUpdate(page + 1, filters, false,true)
              }}
              renderListItem={(item) => (
                <TaskWayPointsListItem
                  wayPoint={item}
                  menuActions={(manager) => menu(manager)}
                />
              )}
            />
          </ErrorBoundary>
        </div>
      )}

      <WaypointsModal
        visible={visibleModal}
        editingWaypoint={currEditWaypoint}
        taskData={props.taskData}
        closeModal={async () => {
          await handleCloseModal();
        }}
      />

      <ErrorBoundary>
        <UniversalFiltersModal
          visibleModal={props.visibleFilters && isMobile}
          handleClose={() => {
            props.setVisibleFilters(false);
          }}
          resetFilters={async () => {
            props.setVisibleFilters(false);
            await props.handleUpdateList(null);
          }}
          columnsList={convertingColumnsWithGroupsIntoRegularOnesForUseInMobileFilters(
            columns,
          )}
        />
      </ErrorBoundary>
    </>
  );
};

export default WaypointsTable;

WaypointsTable.propTypes = {
  waypointsDataList: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool,
  taskData: PropTypes.object,
  visibleFilters: PropTypes.bool,
  tableFilters: PropTypes.any,
  handleUpdateList: PropTypes.func,
};
