import React, { useState } from 'react';
import { Checkbox, Popconfirm } from 'antd';
import {
  DeleteFilled,
  FileAddOutlined,
  CloseSquareOutlined,
  ReloadOutlined,
} from '@ant-design/icons';
import { getNoun } from '../../../utils/getNoun';
import DraftModal from '../DraftModal';

const EmailToolbar = ({
  disabledCanDoActions,
  activeAll,
  handleSpamMessages,
  activeFolder,
  handleFlaggedMessages,
  disabled,
  selectedEmails,
  getMessagesFromFolder,
  handleDeleteMessages,
  deleteAllEmailsFromDeletedFolder,
  setActiveAll,
  setSelectedEmails,
  handleReturnFromSpam,
}) => {
  const [openDraftModal, setOpenDraftModal] = useState(false);

  const confirm = (returnFromSpam = false) => {
    returnFromSpam ? handleReturnFromSpam() : handleDeleteMessages();
  };

  return (
    <div className={'email-toolbar'}>
      <Checkbox
        value={activeAll}
        disabled={disabled}
        onChange={(e) => {
          setActiveAll(e.target.checked);
          !e.target.checked && setSelectedEmails([]);
        }}
        style={{ marginRight: 10, marginLeft: 20 }}
      />

      {/*{activeFolder !== 'deleted' && (
        <div
          style={{ display: 'flex', alignItems: 'center' }}
          className={`email-toolbar__button`}
          onClick={() => {
            getMessagesFromFolder?.(false, !flagged);
            setFlagged(!flagged);
          }}
        >
          <svg
            fill="#d2d2d2"
            style={{ marginRight: 5 }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="20"
            height="20"
          >
            <path fill="none" d="M0 0H24V24H0z" />
            <path d="M21 4L21 6 20 6 14 15 14 22 10 22 10 15 4 6 3 6 3 4z" />
          </svg>
          {!flagged ? 'Сначала важные' : 'По умолчанию'}
        </div>
      )}*/}

      {activeFolder === 'Drafts' && !disabledCanDoActions && (
        <div
          className={`email-toolbar__button`}
          onClick={() => setOpenDraftModal(true)}
        >
          <FileAddOutlined style={{ color: '#7f7f7f' }} />
          Создать черновик
        </div>
      )}
      {activeFolder !== 'deleted' && (
        <Popconfirm
          title={`Вы действительно хотите добавить в важное ${
            selectedEmails?.length
          } ${getNoun(selectedEmails?.length, 'письмо', 'письма', 'писем')}?`}
          onConfirm={() => handleFlaggedMessages?.()}
          disabled={selectedEmails.length === 0 || disabledCanDoActions}
          okText="Да"
          cancelText="Нет"
        >
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className={`email-toolbar__button${
              selectedEmails.length === 0 || disabledCanDoActions
                ? ' disabled'
                : ''
            }`}
          >
            <svg
              style={{ marginRight: 5 }}
              fill="#ffdb4d"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="20"
              height="20"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M5 2h14a1 1 0 0 1 1 1v19.143a.5.5 0 0 1-.766.424L12 18.03l-7.234 4.536A.5.5 0 0 1 4 22.143V3a1 1 0 0 1 1-1z" />
            </svg>
            В важные
          </div>
        </Popconfirm>
      )}

      {activeFolder === 'inbox' && !disabledCanDoActions && (
        <Popconfirm
          title={`Вы действительно хотите добавить в спам ${
            selectedEmails?.length
          } ${getNoun(selectedEmails?.length, 'письмо', 'письма', 'писем')}?`}
          onConfirm={() => handleSpamMessages?.()}
          disabled={selectedEmails.length === 0 || disabledCanDoActions}
          okText="Да"
          cancelText="Нет"
        >
          <div
            style={{ display: 'flex', alignItems: 'center' }}
            className={`email-toolbar__button${
              selectedEmails.length === 0 || disabledCanDoActions
                ? ' disabled'
                : ''
            }`}
          >
            <CloseSquareOutlined />В спам
          </div>
        </Popconfirm>
      )}

      {activeFolder !== 'Trash' && (
        <Popconfirm
          title={`Вы действительно хотите удалить ${
            selectedEmails?.length
          } ${getNoun(selectedEmails?.length, 'письмо', 'письма', 'писем')}?`}
          onConfirm={() => confirm(false)}
          disabled={selectedEmails.length === 0 || disabledCanDoActions}
          okText="Да"
          cancelText="Нет"
        >
          <div
            className={`email-toolbar__button${
              selectedEmails.length === 0 || disabledCanDoActions
                ? ' disabled'
                : ''
            }`}
          >
            <DeleteFilled style={{ color: '#e65050' }} />
            Удалить
          </div>
        </Popconfirm>
      )}
      {activeFolder === 'Spam' && (
        <Popconfirm
          title={`Вы действительно хотите убрать из спама ${
            selectedEmails?.length
          } ${getNoun(selectedEmails?.length, 'письмо', 'письма', 'писем')}?`}
          onConfirm={() => confirm(true)}
          disabled={selectedEmails.length === 0 || disabledCanDoActions}
          okText="Да"
          cancelText="Нет"
        >
          <div
            className={`email-toolbar__button${
              selectedEmails.length === 0 || disabledCanDoActions
                ? ' disabled'
                : ''
            }`}
          >
            <ReloadOutlined />
            Не спам
          </div>
        </Popconfirm>
      )}
      {activeFolder === 'Trash' && (
        <Popconfirm
          title={
            <>
              <div>Очистить папку?</div>
              <div>
                Письма из папки будут удалены навсегда и их нельзя будет
                восстановить
              </div>
            </>
          }
          onConfirm={() => deleteAllEmailsFromDeletedFolder?.()}
          disabled={disabled}
          okText="Да"
          cancelText="Нет"
        >
          <div
            className={`email-toolbar__button${disabled ? ' disabled' : ''}`}
          >
            <DeleteFilled style={{ color: '#e65050' }} />
            Очистить всё
          </div>
        </Popconfirm>
      )}

      <DraftModal
        open={openDraftModal}
        handleClose={() => setOpenDraftModal(false)}
        getMessagesFromFolder={getMessagesFromFolder}
      />
    </div>
  );
};

export default EmailToolbar;
