import PropTypes from 'prop-types';
import useCheckMobileScreen from '../../../../../../../../hooks/useCheckMobileScreen';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { userInformations } from '../../../../../../../../contex';
import { Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { getListOperationWaypoint } from '../../../../../../../../logic/refbook/operationWaypoint/OperationWaypointApiFunctions';
import useAsyncEffect from 'use-async-effect';
import { getListService } from '../../../../../../../../logic/refbook/service/ServiceApiFunction';
import { getShippingTaskTransportationList } from '../../../../../../../../logic/task/TaskApiFunctions';

const { Option } = Select;

const CostModalForm = (props) => {
  const isMobile = useCheckMobileScreen();
  const { userInfo } = useContext(userInformations);
  const [servicesOptions, setServicesOptions] = useState([]);
  const [operationsOptions, setOperationsOptions] = useState([]);
  const [taskTransportList, setTaskTransportList] = useState([]);
  const [loadingDefaultTransport, setLoadingDefaultTransport] = useState(false);

  useAsyncEffect(async () => {
    await handleGetOperationsList();
    await handleGetServicesList();
  }, []);

  async function handleSetDefaultTransportField() {
    try {
      setLoadingDefaultTransport(true);
      const transportList = await handleGetTaskTransportList();
      if (!props.editingCost && transportList?.length) {
        props.form.setFieldsValue({
          transport: transportList[0]?.id ?? null,
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setLoadingDefaultTransport(false);
    }
  }

  useAsyncEffect(async () => {
    if (props.visibleModal) {
      await handleSetDefaultTransportField();
    }
  }, [props.visibleModal, props.editingCost]);

  useEffect(() => {
    props.editingCost?.in_currency && props.setInCurrency(true);
    props.editingCost?.service &&
      handleGetServicesList(props.editingCost.service?.name);
  }, [props.editingCost]);

  const handleGetOperationsList = useCallback(
    async (keyword = null) => {
      const res = await getListOperationWaypoint(userInfo, keyword);
      if (res?.data) {
        setOperationsOptions(res.data);
      }
    },
    [setOperationsOptions],
  );

  const handleGetServicesList = useCallback(
    async (keyword = null) => {
      const res = await getListService(userInfo, keyword);
      if (res?.data) {
        setServicesOptions(res.data);
      }
    },
    [setServicesOptions],
  );

  const handleGetTaskTransportList = useCallback(async () => {
    if (!props.taskData?.id) return;
    const res = await getShippingTaskTransportationList(
      props.taskData?.id,
      null,
      userInfo,
    );
    if (res) {
      setTaskTransportList(res);
      return res;
    }
  }, [props.taskData, setTaskTransportList]);

  const handleSetDefaultPriceValue = useCallback(
    (fieldName) => {
      if (!fieldName) return;
      const value = props.form.getFieldsValue([`${fieldName}`]);
      if (!value[`${fieldName}`]?.trim()) {
        props.form.setFieldsValue({
          [`${fieldName}`]: '0.00',
        });
      }
    },
    [props.form],
  );

  const handleFocusPriceField = useCallback(
    (fieldName) => {
      if (!fieldName) return;
      const value = props.form.getFieldsValue([`${fieldName}`]);
      if (value[`${fieldName}`] === '0.00') {
        props.form.setFieldsValue({
          [`${fieldName}`]: '',
        });
      }
    },
    [props.form],
  );

  return (
    <>
      <Form form={props.form} layout={'vertical'}>
        <Row gutter={16}>
          <Col xs={24} lg={12}>
            <Form.Item name={'operation'} label={'Операция'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                filterOption={false}
                placeholder="Поиск операции"
                onSearch={async (value) => {
                  await handleGetOperationsList(value);
                }}
              >
                {operationsOptions && operationsOptions.length > 0 ? (
                  <>
                    {operationsOptions.map((item) => {
                      return (
                        <Option key={item.id} value={item.id}>
                          {item.name}
                        </Option>
                      );
                    })}
                  </>
                ) : (
                  <Option disabled value="Загрузка">
                    Не найдено
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              name={'service'}
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              label={'Поиск услуги'}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                showSearch
                //loading={searchLoading}
                filterOption={false}
                onSearch={async (value) => await handleGetServicesList(value)}
                placeholder="Найти услугу"
              >
                {servicesOptions.length > 0 ? (
                  <>
                    {Array.isArray(servicesOptions) &&
                      servicesOptions.map((item) => {
                        return (
                          <Option key={item.id} value={item.id}>
                            {item.name}
                          </Option>
                        );
                      })}
                  </>
                ) : null}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'transport'}
              label={'Транспорт'}
            >
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                loading={loadingDefaultTransport}
                placeholder={'Выберите транспорт с заявки'}
              >
                {taskTransportList?.length > 0 ? (
                  taskTransportList.map((item, index) => {
                    return (
                      <Option key={index} value={item.id}>
                        {item?.transport?.transport_unit?.type_transport}
                      </Option>
                    );
                  })
                ) : (
                  <Option disabled value={null}>
                    Нет доступных вариантов
                  </Option>
                )}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item
              valuePropName="checked"
              name={'in_currency'}
              label={'В валюте'}
            >
              <Checkbox
                onChange={(e) => {
                  if (!e.target.checked) {
                    props.form.setFieldsValue({
                      currency: 'rub',
                      currency_rates: '1',
                    });
                  }
                  props.setInCurrency(e.target.checked);
                }}
              />
            </Form.Item>
          </Col>

          <Col xs={24} lg={6}>
            <Form.Item name={'currency'} label={'Валюта'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                defaultValue={'rub'}
                disabled={!props.inCurrency}
              >
                <Option value={'rub'}>Рубли</Option>
                <Option value={'dol'}>Доллар</Option>
                <Option value={'eur'}>Евро</Option>
                <Option value={'cny'}>Юань</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} lg={6}>
            <Form.Item name={'currency_rates'} label={'Курс'}>
              <Input disabled={!props.inCurrency} />
            </Form.Item>
          </Col>
          {props.inCurrency ? (
            <Col xs={24} lg={6}>
              <Form.Item name={'price_currency'} label={'Цена за ед.'}>
                <Input
                  placeholder={'Введите цену за ед.'}
                  onFocus={() => {
                    handleFocusPriceField('price_currency');
                  }}
                  onBlur={() => {
                    handleSetDefaultPriceValue('price_currency');
                  }}
                />
              </Form.Item>
            </Col>
          ) : (
            <Col xs={24} lg={6}>
              <Form.Item name={'price'} label={'Цена за ед.'}>
                <Input
                  placeholder={'Введите цену за ед.'}
                  onFocus={() => {
                    handleFocusPriceField('price');
                  }}
                  onBlur={() => {
                    handleSetDefaultPriceValue('price');
                  }}
                />
              </Form.Item>
            </Col>
          )}
          <Col xs={24} lg={6}>
            <Form.Item name={'vat_rate'} label={'НДС'}>
              <Select
                getPopupContainer={(trigger) =>
                  isMobile ? trigger.parentNode : document.body
                }
                placeholder={'НДС'}
              >
                <Option value={null}>Без НДС</Option>
                <Option value={0}>0%</Option>
                <Option value={20}>20%</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CostModalForm;

CostModalForm.propTypes = {
  form: PropTypes.any.isRequired,
  editingCost: PropTypes.object,
  visibleModal: PropTypes.bool.isRequired,
  taskData: PropTypes.object.isRequired,
  inCurrency: PropTypes.bool.isRequired,
  setInCurrency: PropTypes.func.isRequired,
};
