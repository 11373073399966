import { restApiHost } from '../../utils/appVariables';
import { call } from '../../apiUtils/call';
import { message } from 'antd';
import { flagsChoiceField } from './utils';

export async function getEmailFolders(userInfo, staff_user = null) {
  const urlParams = new URLSearchParams();

  if (staff_user) {
    urlParams.append('user', `${staff_user}`);
  }

  try {
    const url = `${restApiHost}/mail/folders/?${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get email folders', e);
    message.error('Ошибка запроса на получение кол-ва писем по папкам');
  }
}
export async function getMessagesFromFolder(
  folderName,
  page = 1,
  userInfo,
  staff_user = null,
  flagged = false,
) {
  const urlParams = new URLSearchParams();

  urlParams.append('folder', `${folderName}`);

  urlParams.append('page', `${page}`);

  if (staff_user) {
    urlParams.append('user', `${staff_user}`);
  }

  if (flagged) {
    urlParams.append('flagged', `${flagged}`);
  }

  try {
    const url = `${restApiHost}/mail/list/?${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return { data: [], count: 0 };

    return { data: json.data ?? [], count: json.count ?? 0 };
  } catch (e) {
    console.log('fetch get messages from folder', e);
    message.error('Ошибка запроса на получение писем');
  }
}

export async function getEmailMessageByUid(
  messageUid,
  folder = null,
  userInfo,
) {
  if (!messageUid || !folder) return;

  const urlParams = new URLSearchParams();

  urlParams.append('uid', `${messageUid}`);

  urlParams.append('folder', `${folder}`);

  try {
    const url = `${restApiHost}/mail/list/?${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get messages from folder', e);
    message.error('Ошибка запроса на получение писем');
  }
}

export async function changeEmailMessageFlag(
  messageUid,
  flag,
  folder,
  userInfo,
) {
  if (!messageUid || !flagsChoiceField.includes(flag) || !folder) return;

  const body = {
    uid: messageUid,
    folder,
    flag,
  };

  try {
    const url = `${restApiHost}/mail/message/toggle_flag/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch change email message flag', e);
    message.error('Ошибка запроса на изменение флага письма');
  }
}

export async function getMessageAttach(messageUid, filename, folder, userInfo) {
  if (!messageUid || !filename || !folder) return;

  const urlParams = new URLSearchParams();

  urlParams.append('uid', `${messageUid}`);

  urlParams.append('folder', `${folder}`);

  urlParams.append('filename', `${filename}`);

  try {
    const url = `${restApiHost}/mail/message/attach/?${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get message attach', e);
    message.error('Ошибка запроса на получение вложения письма');
  }
}

export async function getEmailContact(userInfo, keyword = null) {
  const urlParams = new URLSearchParams();

  if (keyword) {
    urlParams.append('search', `${keyword}`);
  }

  try {
    const url = `${restApiHost}/mail/contacts/?${urlParams}`;
    const json = await call(
      url,
      {
        method: 'GET',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch get email contact', e);
    message.error('Ошибка запроса на получение контактов');
  }
}

export async function deleteEmailMessage(uid, folder, userInfo) {
  if (!uid || !folder) return;

  const body = {
    uid,
    folder,
  };

  try {
    const url = `${restApiHost}/mail/message/delete/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch delete email message', e);
    message.error('Ошибка запроса на удаление письма');
  }
}

export async function deleteAllEmailMessages(folder, userInfo) {
  if (!folder) return;

  const body = {
    folder,
    all: true,
  };

  try {
    const url = `${restApiHost}/mail/message/delete/`;
    const json = await call(
      url,
      {
        method: 'POST',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      },
      userInfo.current.haveViewBranch,
    );

    if (!json?.data) return;

    return json.data;
  } catch (e) {
    console.log('fetch delete email message', e);
    message.error('Ошибка запроса на удаление письма');
  }
}
