import { Spin, Empty, Button } from 'antd';
import React, { useCallback, useEffect } from 'react';
import EmailListItem from './EmailListItem';
import EmailToolbar from './EmailToolbar';
import useStateRef from 'react-usestateref';

const EmailList = ({
  activeFolder,
  handleDeleteMessages,
  deleteAllEmailsFromDeletedFolder,
  emails,
  total,
  loading,
  showBodyEmail,
  setActiveAll,
  activeAll,
  selectedEmails,
  setSelectedEmails,
  getMessagesFromFolder,
  handleShowSendEmailModal,
  setImportantFlag,
  handleFlaggedMessages,
  flagged,
  setFlagged,
  handleSpamMessages,
  disabledCanDoActions,
  handleReturnFromSpam,
}) => {
  const [, setEmailListCopy, emailListCopyRef] = useStateRef([]);

  useEffect(() => {
    setEmailListCopy(emails);
  }, [emails]);

  const renderEmailList = useCallback(() => {
    return emailListCopyRef.current.map((email) => (
      <EmailListItem
        selectedEmails={selectedEmails}
        setSelectedEmails={setSelectedEmails}
        activeAll={activeAll}
        email={email}
        key={email.uid}
        activeFolder={activeFolder}
        showBodyEmail={showBodyEmail}
        setImportantFlag={setImportantFlag}
        disabledCanDoActions={disabledCanDoActions}
      />
    ));
  }, [
    emailListCopyRef.current,
    selectedEmails,
    setSelectedEmails,
    activeAll,
    activeFolder,
    showBodyEmail,
    setImportantFlag,
    disabledCanDoActions,
  ]);

  return (
    <Spin tip="Загрузка..." spinning={loading}>
      <div style={{ minHeight: '700px' }}>
        <EmailToolbar
          activeAll={activeAll}
          setActiveAll={setActiveAll}
          disabled={
            emailListCopyRef.current?.length === 0 || disabledCanDoActions
          }
          handleShowSendEmailModal={handleShowSendEmailModal}
          handleDeleteMessages={handleDeleteMessages}
          deleteAllEmailsFromDeletedFolder={deleteAllEmailsFromDeletedFolder}
          handleReturnFromSpam={handleReturnFromSpam}
          setSelectedEmails={setSelectedEmails}
          activeFolder={activeFolder}
          getMessagesFromFolder={getMessagesFromFolder}
          flagged={flagged}
          disabledCanDoActions={disabledCanDoActions}
          setFlagged={setFlagged}
          handleFlaggedMessages={handleFlaggedMessages}
          handleSpamMessages={handleSpamMessages}
          selectedEmails={selectedEmails}
        />
        <div className={'email-messages'}>
          {emailListCopyRef.current?.length > 0 ? (
            <>
              {renderEmailList()}

              {emailListCopyRef.current?.length !== total && (
                <Button
                  style={{ marginTop: 10 }}
                  onClick={getMessagesFromFolder}
                >
                  Еще письма
                </Button>
              )}
            </>
          ) : (
            !loading &&
            emailListCopyRef.current?.length <= 0 && (
              <Empty description={<b>Нет писем</b>} />
            )
          )}
        </div>
      </div>
    </Spin>
  );
};

export default EmailList;
