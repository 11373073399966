const replacer = (text) => {
  return text.replace('<', '').replace('>', '');
};

export default function formatBodyMessage(body) {
  if (!body?.length) return '';
  const regexp = /<([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
  // const regexp2 = /<((https?:\/\/)|(\/)|(..\/))(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g
  const regexp3 = /<mailto:([\w.!#$%&’*+/=?^_`{|}~-]+@[\w-]+(?:\.[\w-]+)+)>/g;
  const bodyReplaceEmailBrackets = body
    .replace(regexp, replacer)
    .replace(regexp3, replacer);
  // const addBodySpaces = bodyReplaceEmailBrackets.replace(/>+/g, replacer2);
  // const newLineBodyFormat = addBodySpaces.replace(/\n/g, "<br />");
  return bodyReplaceEmailBrackets;
}

export function decodeBase64(encodedStr) {
  const binaryStr = atob(encodedStr);
  return new TextDecoder('utf-8').decode(
    new Uint8Array([...binaryStr].map((char) => char.charCodeAt(0))),
  );
}
