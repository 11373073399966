import {
  DeleteFilled,
  SendOutlined,
  ArrowLeftOutlined,
  ArrowRightOutlined,
  MailOutlined,
} from '@ant-design/icons';
import React, { useMemo, useState } from 'react';
import SendMessageModal from '../SendMessageModal';
import { Popconfirm } from 'antd';
import ResendEmailModal from '../ReSendEmailModal';
import DraftModal from '../DraftModal';
import { ErrorBoundary } from '../../ErrorBoundary';

const EmailBodyToolbar = ({
  disabledCanDoActions,
  taskType,
  activeFolder,
  profileInfo,
  back,
  email,
  handleDeleteMessageInBody,
  handleShowBodyEmail,
  getMessagesFromFolder,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [loadingSend] = useState(false);
  const [openDraftModal, setOpenDraftModal] = useState(false);
  // const [openChainModal, setOpenChainModal] = useState(false);

  const [listEmails, setListEmails] = useState([]);

  const [openResendModal, setOpenResendModal] = useState(false);
  const [sendAllEmails, setSendAllEmails] = useState(false);

  const sendAll = useMemo(() => {
    const cc = email?.cc?.length ? [...new Set(email.cc)] : [];
    const uniqueEmails = [
      ...new Set(email?.to_emails.map((item) => item.email)),
      ...cc,
    ];
    const emailsWithotRecipient = uniqueEmails.filter(
      (item) => item !== email?.to_email,
    );

    return emailsWithotRecipient?.length > 0;
  }, [email]);

  const sendMessage = (values) => {
    console.log(values);
  };

  const confirm = () => {
    handleDeleteMessageInBody(email);
  };

  /* const handleCloseChainWithTaskModal = () => {
    setOpenChainModal(false);
  };
*/
  return (
    <>
      <div
        className={'email-toolbar'}
        style={{ justifyContent: 'space-between' }}
      >
        <div className={`email-toolbar__button`} onClick={back}>
          <ArrowLeftOutlined style={{ color: '#7f7f7f' }} />
          Назад
        </div>
        {!taskType && !disabledCanDoActions && (
          <>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {activeFolder !== 'Trash' && activeFolder !== 'Drafts' && (
                <div
                  className={`email-toolbar__button`}
                  onClick={() => setOpenResendModal(true)}
                >
                  <ArrowRightOutlined style={{ color: '#7f7f7f' }} />
                  Переслать
                </div>
              )}

              {activeFolder !== 'Trash' && activeFolder !== 'Drafts' && (
                <>
                  <div
                    className={`email-toolbar__button`}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    <SendOutlined style={{ color: '#7f7f7f' }} />
                    Ответить
                  </div>
                  {sendAll && (
                    <div
                      className={`email-toolbar__button`}
                      onClick={() => {
                        if (email?.to_emails.length > 0) {
                          const uniqueEmails = [
                            ...new Set(
                              email.to_emails.map((item) => item.email),
                            ),
                          ];
                          const emailsWithotRecipient = uniqueEmails.filter(
                            (item) => item !== email?.to_email,
                          );
                          const newArr = [
                            email?.from_manager
                              ? email?.to_email
                              : email?.from_email,
                            ...emailsWithotRecipient,
                          ];
                          setListEmails(newArr);
                        } else {
                          return [];
                        }
                        setSendAllEmails(true);
                        setOpenModal(true);
                      }}
                    >
                      <SendOutlined style={{ color: '#7f7f7f' }} />
                      Ответить всем
                    </div>
                  )}
                </>
              )}

              {activeFolder === 'Drafts' && (
                <>
                  {/*<div
                    className={`email-toolbar__button`}
                    onClick={() => setOpenDraftModal(true)}
                  >
                    <EditOutlined style={{ color: '#7f7f7f' }} />
                    Редактировать черновик
                  </div>*/}
                  <div
                    className={`email-toolbar__button`}
                    onClick={() => {
                      setOpenModal(true);
                      const uniqueEmails = [...new Set(email?.to_emails)];
                      uniqueEmails.length === 0
                        ? setListEmails([email?.to_email])
                        : setListEmails(uniqueEmails);
                    }}
                  >
                    <MailOutlined style={{ color: '#7f7f7f' }} />
                    Отправить
                  </div>
                </>
              )}

              {activeFolder !== 'deleted' && (
                <Popconfirm
                  title={`Вы действительно хотите удалить письмо`}
                  onConfirm={confirm}
                  disabled={!email}
                  okText="Да"
                  cancelText="Нет"
                >
                  <div className={`email-toolbar__button`}>
                    <DeleteFilled style={{ color: '#e65050' }} />
                    Удалить
                  </div>
                </Popconfirm>
              )}
            </div>

            {/*<div
              className={`email-toolbar__button`}
              onClick={() => setOpenChainModal(true)}
            >
              Привязать сделку
            </div>*/}
          </>
        )}
      </div>
      <ErrorBoundary>
        <SendMessageModal
          open={openModal}
          loading={loadingSend}
          handleClose={() => {
            setOpenModal(false);
            setSendAllEmails(false);
            setListEmails([]);
          }}
          profileInfo={profileInfo}
          sendAllEmails={sendAllEmails}
          back={back}
          inReplyTo={activeFolder !== 'Drafts'}
          listEmails={listEmails}
          selectedSendObj={email}
          getMessagesFolders={getMessagesFromFolder}
          activeFolder={activeFolder}
          onSearchList={(value) => console.log(value)}
          onSubmit={sendMessage}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <ResendEmailModal
          profileInfo={profileInfo}
          open={openResendModal}
          email={email}
          activeFolder={activeFolder}
          listEmails={[]}
          selectedSendObj={email}
          handleClose={() => setOpenResendModal(false)}
        />
      </ErrorBoundary>

      <ErrorBoundary>
        <DraftModal
          open={openDraftModal}
          profileInfo={profileInfo}
          handleClose={() => setOpenDraftModal(false)}
          getMessagesFromFolder={getMessagesFromFolder}
          selectedSendObj={email}
          handleShowBodyEmail={handleShowBodyEmail}
        />
      </ErrorBoundary>

      {/*<ErrorBoundary>
        <ChainWithTaskModal
          open={openChainModal}
          handleShowBodyEmail={handleShowBodyEmail}
          handleClose={handleCloseChainWithTaskModal}
          email={email}
        />
      </ErrorBoundary>*/}
    </>
  );
};

export default EmailBodyToolbar;
