import React, { useEffect, useState, useContext } from 'react';
import {
  Button,
  Col,
  Collapse,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import { restApiHost } from '../../utils/appVariables';
import 'moment/locale/ru';
import MultipleSelectUseSearch from '../UiComponents/selects/MultipleSelectUseSearch';
import { call } from '../../apiUtils/call';
import { userInformations } from '../../contex';
import * as ClassicEditor from '../../ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import useStateRef from 'react-usestateref';

const { Option } = Select;
const { Panel } = Collapse;

const DraftModal = ({
  open,
  handleClose,
  //listEmails,
  selectedSendObj,
  getMessagesFromFolder,
  //handleShowBodyEmail,
}) => {
  const [form] = Form.useForm();
  const [contacts /*setContacts*/] = useState([]);
  //const [fileList, setFileList] = useState([]);
  const [loadingSend, setLoadingSend] = useState(false);
  const { userInfo } = useContext(userInformations);

  const [, setEmailHtml, emailHtmlRef] = useStateRef('');

  /*const editorRef = useRef();
  const editorInstance = useRef();
*/
  /*  useEffect(() => {
    if (open && editorInstance?.current) {
      editorInstance.current.editing.view.focus();
    }
    if (open && editorRef.current && !editorInstance.current) {
      ClassicEditor.create(editorRef.current)
        .then((editor) => {
          editorInstance.current = editor;
          editor.editing.view.focus();
          editor.setData(emailHtml);
        })
        .catch((error) => {
          console.error('Oops, something went wrong!');
          console.error(
            'Please, report the following error on https://github.com/ckeditor/ckeditor5/issues with the build id and the error stack trace:',
          );
          console.error(error);
        });
    }
  }, [editorRef, open, editorInstance, emailHtml]);*/

  useEffect(() => {
    if (userInfo.current && !selectedSendObj) {
      if (userInfo.current?.signature) {
        setEmailHtml(userInfo.current.signature);
      }
    }
  }, [open]);

  /*useEffect(() => {
    if (selectedSendObj) {
      form.setFieldsValue({
        ...selectedSendObj,
        to_email:
          selectedSendObj?.to_emails?.length > 0
            ? selectedSendObj.to_emails.map((item) => item.email)
            : [selectedSendObj.to_email],
      });
      if (selectedSendObj?.body) {
        let html = selectedSendObj.body;
        setEmailHtml(html);
        editorInstance?.current?.setData(html);
      }
    } else {
      form.resetFields();
    }
  }, [selectedSendObj]);*/

  /* const searchContacts = async (keyword) => {
    try {
      const url = `${restApiHost}/mail/search_contacts/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ keyword }),
        },
        userInfo.current.haveViewBranch,
      );

      if (json?.data) {
        setContacts(json.data);
      }
    } catch (e) {
      console.log(e);
      message.error('Ошибка запроса на поиск контактов');
    }
  };*/

  /* const handleSearchContacts = async (keyword) => {
    if (keyword.length > 0) {
      await searchContacts(keyword);
    }
  };*/

  /*const props = {
    beforeUpload: () => false,
    onChange({ file, fileList }) {
      if (file.status !== 'uploading') {
        setFileList(fileList);
      }
    },
    multiple: true,
    defaultFileList: fileList,
  };*/

  const handleCreateDraft = async (values) => {
    setLoadingSend(true);
    try {
      const formData = new FormData();
      for (let key in values) {
        if (
          (key === 'emails' && values[key]) ||
          (key === 'cc' && values[key]) ||
          (key === 'bcc' && values[key])
        ) {
          Array.isArray(values[key]) &&
            values[key]?.map((item) => formData.append(key, `${item}`));
        } else {
          values[key] && formData.append(key, `${values[key]}`);
        }
      }

      formData.append('draft', 'true');
      formData.append('html', emailHtmlRef.current);

      const url = `${restApiHost}/mail/send/`;
      const json = await call(
        url,
        {
          method: 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current?.haveViewBranch,
      );

      if (!json) return;

      form.resetFields();

      message.success('Черновик успешно создан!');

      getMessagesFromFolder && getMessagesFromFolder(false, false);
      handleClose();
    } catch (e) {
      console.log(e);
      message.error('Ошибка отправки письма');
    } finally {
      setLoadingSend(false);
    }
  };

  /*const createDraft = async (values, edit = false) => {
    setLoadingSend(true);
    try {
      const formData = new FormData();
      for (let key in values) {
        if (key === 'to_emails' && values[key]) {
          formData.append(key, values[key].join(','));
        } else {
          values[key] && formData.append(key, `${values[key]}`);
        }
      }
      // formData.append('footer', 'true');
      formData.append('body', editorInstance?.current?.getData());
      selectedSendObj?.id &&
        formData.append('in_reply_to', `${selectedSendObj.id}`);

      if (fileList.length > 0) {
        fileList.map((file) => {
          if (file.originFileObj) {
            formData.append('attached_files', file.originFileObj, file.name);
          }
        });
      }

      const url =
        edit && selectedSendObj?.id
          ? `${restApiHost}/mail/messages/${selectedSendObj.id}/`
          : `${restApiHost}/mail/messages/`;
      const json = await call(
        url,
        {
          method: edit && selectedSendObj?.id ? 'PATCH' : 'POST',
          headers: {
            Authorization: `Token ${localStorage.getItem('token')}`,
          },
          body: formData,
        },
        userInfo.current?.haveViewBranch,
      );

      if (!json) return;

      message.success('Черновик успешно сохранён!');
      getMessagesFromFolder && getMessagesFromFolder(false);
      selectedSendObj &&
        handleShowBodyEmail &&
        handleShowBodyEmail(selectedSendObj);
      setFileList([]);
      handleClose();
      setLoadingSend(false);
    } catch (e) {
      message.error(`Ошибка запроса на сохранение черновик, ${e}`);
    } finally {
      setLoadingSend(false);
    }
  };*/

  return (
    <Modal
      visible={open}
      title={'Черновик'}
      width={1000}
      onCancel={handleClose}
      footer={[
        <Button key="close" onClick={handleClose}>
          Закрыть
        </Button>,
        // <>
        // {selectedSendObj && (
        //     <Button
        //         key="create"
        //         type="primary"
        //         disabled={loadingSend}
        //         onClick={() => {
        //             form.validateFields().then(values => {
        //                 values && sendEmail(values);
        //             })
        //         }}
        //         loading={loadingSend}
        //     >
        //         Отправить письмо
        //     </Button>
        // )}
        // </>,
        <Button
          key="save"
          type="primary"
          disabled={loadingSend}
          onClick={() => {
            form.validateFields().then(async (values) => {
              values && (await handleCreateDraft(values));
            });
          }}
          loading={loadingSend}
        >
          Сохранить черновик
        </Button>,
      ]}
    >
      <Form form={form} className={'reset-margin-form'}>
        <Row gutter={15} className={'color-disabled'}>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'emails'}
            >
              <MultipleSelectUseSearch
                onSearch={() => {}}
                placeholder="Кому"
                style={{
                  width: '100%',
                }}
                options={contacts.map((contact) => {
                  return (
                    <Option key={contact.email} value={contact.email}>
                      {contact.name} {`<${contact.email}>`}
                    </Option>
                  );
                })}
              />
            </Form.Item>
          </Col>
          <Col lg={24}>
            <Collapse className={'email-draft-collapse'} ghost>
              <Panel header="Копии" key="1">
                <Col lg={24}>
                  <Form.Item name={'cc'}>
                    <Select
                      onSearch={() => {}}
                      mode="tags"
                      placeholder="Копия"
                      defaultValue={[]}
                      style={{
                        width: '100%',
                      }}
                    >
                      {contacts.map((contact) => {
                        return (
                          <Option key={contact.email} value={contact.email}>
                            {contact.email} | {contact.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col lg={24}>
                  <Form.Item name={'bcc'}>
                    <Select
                      onSearch={() => {}}
                      mode="tags"
                      placeholder="Скрытая копия"
                      defaultValue={[]}
                      style={{
                        width: '100%',
                      }}
                    >
                      {contacts.map((contact) => {
                        return (
                          <Option key={contact.email} value={contact.email}>
                            {contact.email} | {contact.name}
                          </Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
              </Panel>
            </Collapse>
          </Col>
          <Col lg={24} md={24} xs={24}>
            <Form.Item
              rules={[{ required: true, message: 'Обязательное поле!' }]}
              name={'subject'}
            >
              <Input placeholder={'Тема'} />
            </Form.Item>
          </Col>
          {/*<Col lg={24} style={{margin: '10px 0'}}>*/}
          {/*    <Upload {...props} className={'upload-container'}>*/}
          {/*        <Button icon={<PaperClipOutlined/>}>Прикрепить файлы</Button>*/}
          {/*    </Upload>*/}
          {/*</Col>*/}
          <Col lg={24} md={24} xs={24}>
            <div className="email-editor">
              <CKEditor
                editor={ClassicEditor}
                data={emailHtmlRef.current}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  if (data) {
                    setEmailHtml(data);
                  }
                }}
              />
            </div>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default DraftModal;
